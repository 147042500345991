import { useEffect } from 'react'
import { useViewModel } from '~/client/application/auth/useViewModel'

export default function AuthHandler() {
  const onInitialize = useViewModel((m) => m?.onInitialize)
  const onDeinitialize = useViewModel((m) => m?.onDeinitialize)

  useEffect(() => {
    onInitialize?.()
    return onDeinitialize
  }, [])

  return null
}
